@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-size: 16px;
  font-weight: 400;
}
/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}


a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}
.navItem {
  @apply flex;
  @apply list-none;
  @apply focus:outline-none;
  @apply cursor-pointer;
  @apply text-gray-400;
  @apply h-12;
}

.navItem.active {
  @apply md:border-b-4;
  @apply text-white;
}

.navItem.active .navLink {
  @apply text-white;
}

.navItem .navLink {
  @apply text-gray-400;
  @apply focus:outline-none;
  @apply py-2;
  @apply px-3;
  @apply text-xl;
  @apply font-thin;
  @apply hover:text-gray-50;
  @apply hover:no-underline;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-button {
  width: 8px;
  height:5px;
}
::-webkit-scrollbar-track {
  background:#eee;
  border: thin solid lightgray;
  box-shadow: 0px 0px 3px #dfdfdf inset;
  border-radius:10px;
}
::-webkit-scrollbar-thumb {
  background:#999;
  border: thin solid gray;
  border-radius:10px;
}
::-webkit-scrollbar-thumb:hover {
  background:#7d7d7d;
}